/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountService } from './services/account.service';
import { BudgetService } from './services/budget.service';
import { CompanyService } from './services/company.service';
import { ContactService } from './services/contact.service';
import { ContactImageService } from './services/contact-image.service';
import { CostManagerService } from './services/cost-manager.service';
import { DealService } from './services/deal.service';
import { FileManagerService } from './services/file-manager.service';
import { FormService } from './services/form.service';
import { GithubService } from './services/github.service';
import { HolidayService } from './services/holiday.service';
import { InvoiceService } from './services/invoice.service';
import { ProductService } from './services/product.service';
import { ProjectService } from './services/project.service';
import { PurchaseOrderService } from './services/purchase-order.service';
import { ReportService } from './services/report.service';
import { ResourceService } from './services/resource.service';
import { ScheduleService } from './services/schedule.service';
import { ScrumService } from './services/scrum.service';
import { SettingsService } from './services/settings.service';
import { StaffRateService } from './services/staff-rate.service';
import { SupplierService } from './services/supplier.service';
import { SystemNotificationService } from './services/system-notification.service';
import { ToDoService } from './services/to-do.service';
import { UserImageService } from './services/user-image.service';
import { WorkloadService } from './services/workload.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountService,
    BudgetService,
    CompanyService,
    ContactService,
    ContactImageService,
    CostManagerService,
    DealService,
    FileManagerService,
    FormService,
    GithubService,
    HolidayService,
    InvoiceService,
    ProductService,
    ProjectService,
    PurchaseOrderService,
    ReportService,
    ResourceService,
    ScheduleService,
    ScrumService,
    SettingsService,
    StaffRateService,
    SupplierService,
    SystemNotificationService,
    ToDoService,
    UserImageService,
    WorkloadService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
