/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { SystemNotificationDto } from '../models/system-notification-dto';
import { SystemNotificationDtoListBaseResponse } from '../models/system-notification-dto-list-base-response';

@Injectable({
  providedIn: 'root',
})
export class SystemNotificationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSystemNotificationForUserGet
   */
  static readonly ApiSystemNotificationForUserGetPath = '/api/SystemNotification/for-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSystemNotificationForUserGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSystemNotificationForUserGet$Plain$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<SystemNotificationDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SystemNotificationService.ApiSystemNotificationForUserGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SystemNotificationDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSystemNotificationForUserGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSystemNotificationForUserGet$Plain(params?: {
    userId?: number;
  }): Observable<SystemNotificationDtoListBaseResponse> {

    return this.apiSystemNotificationForUserGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SystemNotificationDtoListBaseResponse>) => r.body as SystemNotificationDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSystemNotificationForUserGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSystemNotificationForUserGet$Json$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<SystemNotificationDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SystemNotificationService.ApiSystemNotificationForUserGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SystemNotificationDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSystemNotificationForUserGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSystemNotificationForUserGet$Json(params?: {
    userId?: number;
  }): Observable<SystemNotificationDtoListBaseResponse> {

    return this.apiSystemNotificationForUserGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SystemNotificationDtoListBaseResponse>) => r.body as SystemNotificationDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiSystemNotificationUpdateNotificationPut
   */
  static readonly ApiSystemNotificationUpdateNotificationPutPath = '/api/SystemNotification/update-notification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSystemNotificationUpdateNotificationPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSystemNotificationUpdateNotificationPut$Plain$Response(params?: {
    body?: SystemNotificationDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SystemNotificationService.ApiSystemNotificationUpdateNotificationPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSystemNotificationUpdateNotificationPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSystemNotificationUpdateNotificationPut$Plain(params?: {
    body?: SystemNotificationDto
  }): Observable<BooleanBaseResponse> {

    return this.apiSystemNotificationUpdateNotificationPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSystemNotificationUpdateNotificationPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSystemNotificationUpdateNotificationPut$Json$Response(params?: {
    body?: SystemNotificationDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SystemNotificationService.ApiSystemNotificationUpdateNotificationPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSystemNotificationUpdateNotificationPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSystemNotificationUpdateNotificationPut$Json(params?: {
    body?: SystemNotificationDto
  }): Observable<BooleanBaseResponse> {

    return this.apiSystemNotificationUpdateNotificationPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiSystemNotificationMarkAllReadPut
   */
  static readonly ApiSystemNotificationMarkAllReadPutPath = '/api/SystemNotification/mark-all-read';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSystemNotificationMarkAllReadPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSystemNotificationMarkAllReadPut$Plain$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SystemNotificationService.ApiSystemNotificationMarkAllReadPutPath, 'put');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSystemNotificationMarkAllReadPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSystemNotificationMarkAllReadPut$Plain(params?: {
    userId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiSystemNotificationMarkAllReadPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSystemNotificationMarkAllReadPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSystemNotificationMarkAllReadPut$Json$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SystemNotificationService.ApiSystemNotificationMarkAllReadPutPath, 'put');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSystemNotificationMarkAllReadPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSystemNotificationMarkAllReadPut$Json(params?: {
    userId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiSystemNotificationMarkAllReadPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiSystemNotificationArchiveDelete
   */
  static readonly ApiSystemNotificationArchiveDeletePath = '/api/SystemNotification/archive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSystemNotificationArchiveDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSystemNotificationArchiveDelete$Plain$Response(params?: {
    notificationId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SystemNotificationService.ApiSystemNotificationArchiveDeletePath, 'delete');
    if (params) {
      rb.query('notificationId', params.notificationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSystemNotificationArchiveDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSystemNotificationArchiveDelete$Plain(params?: {
    notificationId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiSystemNotificationArchiveDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSystemNotificationArchiveDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSystemNotificationArchiveDelete$Json$Response(params?: {
    notificationId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SystemNotificationService.ApiSystemNotificationArchiveDeletePath, 'delete');
    if (params) {
      rb.query('notificationId', params.notificationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSystemNotificationArchiveDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSystemNotificationArchiveDelete$Json(params?: {
    notificationId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiSystemNotificationArchiveDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
