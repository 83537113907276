/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { DashboardTaskStatsDtoBaseResponse } from '../models/dashboard-task-stats-dto-base-response';
import { DecimalBaseResponse } from '../models/decimal-base-response';
import { OrderDirection } from '../models/order-direction';
import { PropertyFilter } from '../models/property-filter';
import { ScrumBoardDetailDtoBaseResponse } from '../models/scrum-board-detail-dto-base-response';
import { ScrumBoardListDtoBaseResponse } from '../models/scrum-board-list-dto-base-response';
import { ScrumBoardListDtoListBaseResponse } from '../models/scrum-board-list-dto-list-base-response';
import { ScrumCardBasicDtoPagedResponseBaseResponse } from '../models/scrum-card-basic-dto-paged-response-base-response';
import { ScrumCardCommentDto } from '../models/scrum-card-comment-dto';
import { ScrumCardCommentDtoBaseResponse } from '../models/scrum-card-comment-dto-base-response';
import { ScrumCardCommentDtoListBaseResponse } from '../models/scrum-card-comment-dto-list-base-response';
import { ScrumCardDto } from '../models/scrum-card-dto';
import { ScrumCardDtoBaseResponse } from '../models/scrum-card-dto-base-response';
import { ScrumCardDtoListBaseResponse } from '../models/scrum-card-dto-list-base-response';
import { ScrumCardHistoryDto } from '../models/scrum-card-history-dto';
import { ScrumCardListDtoListBaseResponse } from '../models/scrum-card-list-dto-list-base-response';
import { ScrumLabelDto } from '../models/scrum-label-dto';
import { ScrumLabelDtoBaseResponse } from '../models/scrum-label-dto-base-response';
import { ScrumLabelDtoListBaseResponse } from '../models/scrum-label-dto-list-base-response';
import { SwimlaneDetailDto } from '../models/swimlane-detail-dto';
import { SwimlaneListDto } from '../models/swimlane-list-dto';
import { SwimlaneNotificationDtoListBaseResponse } from '../models/swimlane-notification-dto-list-base-response';
import { SwimlaneNotificationsDto } from '../models/swimlane-notifications-dto';
import { TaskFileDto } from '../models/task-file-dto';
import { TaskFileDtoBaseResponse } from '../models/task-file-dto-base-response';
import { TaskFileDtoListBaseResponse } from '../models/task-file-dto-list-base-response';
import { WorkTaskListDto } from '../models/work-task-list-dto';
import { WorkTaskListDtoListBaseResponse } from '../models/work-task-list-dto-list-base-response';

@Injectable({
  providedIn: 'root',
})
export class ScrumService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiScrumAllBoardsGet
   */
  static readonly ApiScrumAllBoardsGetPath = '/api/Scrum/all-boards';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumAllBoardsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumAllBoardsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<ScrumBoardListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumAllBoardsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumBoardListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumAllBoardsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumAllBoardsGet$Plain(params?: {
  }): Observable<ScrumBoardListDtoListBaseResponse> {

    return this.apiScrumAllBoardsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumBoardListDtoListBaseResponse>) => r.body as ScrumBoardListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumAllBoardsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumAllBoardsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<ScrumBoardListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumAllBoardsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumBoardListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumAllBoardsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumAllBoardsGet$Json(params?: {
  }): Observable<ScrumBoardListDtoListBaseResponse> {

    return this.apiScrumAllBoardsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumBoardListDtoListBaseResponse>) => r.body as ScrumBoardListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumBoardGet
   */
  static readonly ApiScrumBoardGetPath = '/api/Scrum/board';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumBoardGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumBoardGet$Plain$Response(params?: {
    boardId?: number;
  }): Observable<StrictHttpResponse<ScrumBoardDetailDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumBoardGetPath, 'get');
    if (params) {
      rb.query('boardId', params.boardId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumBoardDetailDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumBoardGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumBoardGet$Plain(params?: {
    boardId?: number;
  }): Observable<ScrumBoardDetailDtoBaseResponse> {

    return this.apiScrumBoardGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumBoardDetailDtoBaseResponse>) => r.body as ScrumBoardDetailDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumBoardGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumBoardGet$Json$Response(params?: {
    boardId?: number;
  }): Observable<StrictHttpResponse<ScrumBoardDetailDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumBoardGetPath, 'get');
    if (params) {
      rb.query('boardId', params.boardId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumBoardDetailDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumBoardGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumBoardGet$Json(params?: {
    boardId?: number;
  }): Observable<ScrumBoardDetailDtoBaseResponse> {

    return this.apiScrumBoardGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumBoardDetailDtoBaseResponse>) => r.body as ScrumBoardDetailDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumBoardForProjectGet
   */
  static readonly ApiScrumBoardForProjectGetPath = '/api/Scrum/board-for-project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumBoardForProjectGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumBoardForProjectGet$Plain$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<ScrumBoardDetailDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumBoardForProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumBoardDetailDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumBoardForProjectGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumBoardForProjectGet$Plain(params?: {
    projectId?: number;
  }): Observable<ScrumBoardDetailDtoBaseResponse> {

    return this.apiScrumBoardForProjectGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumBoardDetailDtoBaseResponse>) => r.body as ScrumBoardDetailDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumBoardForProjectGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumBoardForProjectGet$Json$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<ScrumBoardDetailDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumBoardForProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumBoardDetailDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumBoardForProjectGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumBoardForProjectGet$Json(params?: {
    projectId?: number;
  }): Observable<ScrumBoardDetailDtoBaseResponse> {

    return this.apiScrumBoardForProjectGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumBoardDetailDtoBaseResponse>) => r.body as ScrumBoardDetailDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumBasicBoardForProjectGet
   */
  static readonly ApiScrumBasicBoardForProjectGetPath = '/api/Scrum/basic-board-for-project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumBasicBoardForProjectGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumBasicBoardForProjectGet$Plain$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<ScrumBoardListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumBasicBoardForProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumBoardListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumBasicBoardForProjectGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumBasicBoardForProjectGet$Plain(params?: {
    projectId?: number;
  }): Observable<ScrumBoardListDtoBaseResponse> {

    return this.apiScrumBasicBoardForProjectGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumBoardListDtoBaseResponse>) => r.body as ScrumBoardListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumBasicBoardForProjectGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumBasicBoardForProjectGet$Json$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<ScrumBoardListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumBasicBoardForProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumBoardListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumBasicBoardForProjectGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumBasicBoardForProjectGet$Json(params?: {
    projectId?: number;
  }): Observable<ScrumBoardListDtoBaseResponse> {

    return this.apiScrumBasicBoardForProjectGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumBoardListDtoBaseResponse>) => r.body as ScrumBoardListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumPaginatedCardsForSwimlaneGet
   */
  static readonly ApiScrumPaginatedCardsForSwimlaneGetPath = '/api/Scrum/paginated-cards-for-swimlane';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumPaginatedCardsForSwimlaneGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumPaginatedCardsForSwimlaneGet$Plain$Response(params?: {
    SwimlaneId?: number;
    LabelId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<ScrumCardBasicDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumPaginatedCardsForSwimlaneGetPath, 'get');
    if (params) {
      rb.query('SwimlaneId', params.SwimlaneId, {});
      rb.query('LabelId', params.LabelId, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardBasicDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumPaginatedCardsForSwimlaneGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumPaginatedCardsForSwimlaneGet$Plain(params?: {
    SwimlaneId?: number;
    LabelId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<ScrumCardBasicDtoPagedResponseBaseResponse> {

    return this.apiScrumPaginatedCardsForSwimlaneGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardBasicDtoPagedResponseBaseResponse>) => r.body as ScrumCardBasicDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumPaginatedCardsForSwimlaneGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumPaginatedCardsForSwimlaneGet$Json$Response(params?: {
    SwimlaneId?: number;
    LabelId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<ScrumCardBasicDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumPaginatedCardsForSwimlaneGetPath, 'get');
    if (params) {
      rb.query('SwimlaneId', params.SwimlaneId, {});
      rb.query('LabelId', params.LabelId, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardBasicDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumPaginatedCardsForSwimlaneGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumPaginatedCardsForSwimlaneGet$Json(params?: {
    SwimlaneId?: number;
    LabelId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<ScrumCardBasicDtoPagedResponseBaseResponse> {

    return this.apiScrumPaginatedCardsForSwimlaneGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardBasicDtoPagedResponseBaseResponse>) => r.body as ScrumCardBasicDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumCardsForProjectGet
   */
  static readonly ApiScrumCardsForProjectGetPath = '/api/Scrum/cards-for-project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumCardsForProjectGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardsForProjectGet$Plain$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<ScrumCardDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumCardsForProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumCardsForProjectGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardsForProjectGet$Plain(params?: {
    projectId?: number;
  }): Observable<ScrumCardDtoListBaseResponse> {

    return this.apiScrumCardsForProjectGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardDtoListBaseResponse>) => r.body as ScrumCardDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumCardsForProjectGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardsForProjectGet$Json$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<ScrumCardDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumCardsForProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumCardsForProjectGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardsForProjectGet$Json(params?: {
    projectId?: number;
  }): Observable<ScrumCardDtoListBaseResponse> {

    return this.apiScrumCardsForProjectGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardDtoListBaseResponse>) => r.body as ScrumCardDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumCardListForProjectGet
   */
  static readonly ApiScrumCardListForProjectGetPath = '/api/Scrum/card-list-for-project';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumCardListForProjectGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardListForProjectGet$Plain$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<ScrumCardListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumCardListForProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumCardListForProjectGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardListForProjectGet$Plain(params?: {
    projectId?: number;
  }): Observable<ScrumCardListDtoListBaseResponse> {

    return this.apiScrumCardListForProjectGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardListDtoListBaseResponse>) => r.body as ScrumCardListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumCardListForProjectGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardListForProjectGet$Json$Response(params?: {
    projectId?: number;
  }): Observable<StrictHttpResponse<ScrumCardListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumCardListForProjectGetPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumCardListForProjectGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardListForProjectGet$Json(params?: {
    projectId?: number;
  }): Observable<ScrumCardListDtoListBaseResponse> {

    return this.apiScrumCardListForProjectGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardListDtoListBaseResponse>) => r.body as ScrumCardListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumCardsForUserInDateGet
   */
  static readonly ApiScrumCardsForUserInDateGetPath = '/api/Scrum/cards-for-user-in-date';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumCardsForUserInDateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardsForUserInDateGet$Plain$Response(params?: {
    userId?: number;
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<ScrumCardDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumCardsForUserInDateGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumCardsForUserInDateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardsForUserInDateGet$Plain(params?: {
    userId?: number;
    startDate?: string;
    endDate?: string;
  }): Observable<ScrumCardDtoListBaseResponse> {

    return this.apiScrumCardsForUserInDateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardDtoListBaseResponse>) => r.body as ScrumCardDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumCardsForUserInDateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardsForUserInDateGet$Json$Response(params?: {
    userId?: number;
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<ScrumCardDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumCardsForUserInDateGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumCardsForUserInDateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardsForUserInDateGet$Json(params?: {
    userId?: number;
    startDate?: string;
    endDate?: string;
  }): Observable<ScrumCardDtoListBaseResponse> {

    return this.apiScrumCardsForUserInDateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardDtoListBaseResponse>) => r.body as ScrumCardDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumDashboardTaskStatsGet
   */
  static readonly ApiScrumDashboardTaskStatsGetPath = '/api/Scrum/dashboard-task-stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumDashboardTaskStatsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumDashboardTaskStatsGet$Plain$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<DashboardTaskStatsDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumDashboardTaskStatsGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DashboardTaskStatsDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumDashboardTaskStatsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumDashboardTaskStatsGet$Plain(params?: {
    userId?: number;
  }): Observable<DashboardTaskStatsDtoBaseResponse> {

    return this.apiScrumDashboardTaskStatsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DashboardTaskStatsDtoBaseResponse>) => r.body as DashboardTaskStatsDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumDashboardTaskStatsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumDashboardTaskStatsGet$Json$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<DashboardTaskStatsDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumDashboardTaskStatsGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DashboardTaskStatsDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumDashboardTaskStatsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumDashboardTaskStatsGet$Json(params?: {
    userId?: number;
  }): Observable<DashboardTaskStatsDtoBaseResponse> {

    return this.apiScrumDashboardTaskStatsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DashboardTaskStatsDtoBaseResponse>) => r.body as DashboardTaskStatsDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumCardsAsWorkTasksGet
   */
  static readonly ApiScrumCardsAsWorkTasksGetPath = '/api/Scrum/cards-as-work-tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumCardsAsWorkTasksGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardsAsWorkTasksGet$Plain$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<WorkTaskListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumCardsAsWorkTasksGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkTaskListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumCardsAsWorkTasksGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardsAsWorkTasksGet$Plain(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<WorkTaskListDtoListBaseResponse> {

    return this.apiScrumCardsAsWorkTasksGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WorkTaskListDtoListBaseResponse>) => r.body as WorkTaskListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumCardsAsWorkTasksGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardsAsWorkTasksGet$Json$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<WorkTaskListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumCardsAsWorkTasksGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorkTaskListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumCardsAsWorkTasksGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardsAsWorkTasksGet$Json(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<WorkTaskListDtoListBaseResponse> {

    return this.apiScrumCardsAsWorkTasksGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WorkTaskListDtoListBaseResponse>) => r.body as WorkTaskListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumUpdateCardWorktaskDetailsPut
   */
  static readonly ApiScrumUpdateCardWorktaskDetailsPutPath = '/api/Scrum/update-card-worktask-details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUpdateCardWorktaskDetailsPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateCardWorktaskDetailsPut$Plain$Response(params?: {
    body?: WorkTaskListDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUpdateCardWorktaskDetailsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUpdateCardWorktaskDetailsPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateCardWorktaskDetailsPut$Plain(params?: {
    body?: WorkTaskListDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumUpdateCardWorktaskDetailsPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUpdateCardWorktaskDetailsPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateCardWorktaskDetailsPut$Json$Response(params?: {
    body?: WorkTaskListDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUpdateCardWorktaskDetailsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUpdateCardWorktaskDetailsPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateCardWorktaskDetailsPut$Json(params?: {
    body?: WorkTaskListDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumUpdateCardWorktaskDetailsPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumHoursLoggedForUserInDateGet
   */
  static readonly ApiScrumHoursLoggedForUserInDateGetPath = '/api/Scrum/hours-logged-for-user-in-date';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumHoursLoggedForUserInDateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumHoursLoggedForUserInDateGet$Plain$Response(params?: {
    userId?: number;
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<DecimalBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumHoursLoggedForUserInDateGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DecimalBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumHoursLoggedForUserInDateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumHoursLoggedForUserInDateGet$Plain(params?: {
    userId?: number;
    startDate?: string;
    endDate?: string;
  }): Observable<DecimalBaseResponse> {

    return this.apiScrumHoursLoggedForUserInDateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DecimalBaseResponse>) => r.body as DecimalBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumHoursLoggedForUserInDateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumHoursLoggedForUserInDateGet$Json$Response(params?: {
    userId?: number;
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<DecimalBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumHoursLoggedForUserInDateGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DecimalBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumHoursLoggedForUserInDateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumHoursLoggedForUserInDateGet$Json(params?: {
    userId?: number;
    startDate?: string;
    endDate?: string;
  }): Observable<DecimalBaseResponse> {

    return this.apiScrumHoursLoggedForUserInDateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DecimalBaseResponse>) => r.body as DecimalBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumUpdateSwimlanesPut
   */
  static readonly ApiScrumUpdateSwimlanesPutPath = '/api/Scrum/update-swimlanes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUpdateSwimlanesPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateSwimlanesPut$Plain$Response(params?: {
    body?: Array<SwimlaneListDto>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUpdateSwimlanesPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUpdateSwimlanesPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateSwimlanesPut$Plain(params?: {
    body?: Array<SwimlaneListDto>
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumUpdateSwimlanesPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUpdateSwimlanesPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateSwimlanesPut$Json$Response(params?: {
    body?: Array<SwimlaneListDto>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUpdateSwimlanesPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUpdateSwimlanesPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateSwimlanesPut$Json(params?: {
    body?: Array<SwimlaneListDto>
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumUpdateSwimlanesPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumUpdateCardPositionsPut
   */
  static readonly ApiScrumUpdateCardPositionsPutPath = '/api/Scrum/update-card-positions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUpdateCardPositionsPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateCardPositionsPut$Plain$Response(params?: {
    body?: Array<ScrumCardDto>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUpdateCardPositionsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUpdateCardPositionsPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateCardPositionsPut$Plain(params?: {
    body?: Array<ScrumCardDto>
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumUpdateCardPositionsPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUpdateCardPositionsPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateCardPositionsPut$Json$Response(params?: {
    body?: Array<ScrumCardDto>
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUpdateCardPositionsPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUpdateCardPositionsPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateCardPositionsPut$Json(params?: {
    body?: Array<ScrumCardDto>
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumUpdateCardPositionsPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumUpdateCardDetailsPut
   */
  static readonly ApiScrumUpdateCardDetailsPutPath = '/api/Scrum/update-card-details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUpdateCardDetailsPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateCardDetailsPut$Plain$Response(params?: {
    currentUserId?: number;
    body?: ScrumCardDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUpdateCardDetailsPutPath, 'put');
    if (params) {
      rb.query('currentUserId', params.currentUserId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUpdateCardDetailsPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateCardDetailsPut$Plain(params?: {
    currentUserId?: number;
    body?: ScrumCardDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumUpdateCardDetailsPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUpdateCardDetailsPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateCardDetailsPut$Json$Response(params?: {
    currentUserId?: number;
    body?: ScrumCardDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUpdateCardDetailsPutPath, 'put');
    if (params) {
      rb.query('currentUserId', params.currentUserId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUpdateCardDetailsPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateCardDetailsPut$Json(params?: {
    currentUserId?: number;
    body?: ScrumCardDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumUpdateCardDetailsPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumAddCardHistoryPost
   */
  static readonly ApiScrumAddCardHistoryPostPath = '/api/Scrum/add-card-history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumAddCardHistoryPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumAddCardHistoryPost$Plain$Response(params?: {
    body?: ScrumCardHistoryDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumAddCardHistoryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumAddCardHistoryPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumAddCardHistoryPost$Plain(params?: {
    body?: ScrumCardHistoryDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumAddCardHistoryPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumAddCardHistoryPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumAddCardHistoryPost$Json$Response(params?: {
    body?: ScrumCardHistoryDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumAddCardHistoryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumAddCardHistoryPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumAddCardHistoryPost$Json(params?: {
    body?: ScrumCardHistoryDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumAddCardHistoryPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumCompleteTaskPut
   */
  static readonly ApiScrumCompleteTaskPutPath = '/api/Scrum/complete-task';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumCompleteTaskPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCompleteTaskPut$Plain$Response(params?: {
    cardId?: number;
    userId?: number;
    completed?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumCompleteTaskPutPath, 'put');
    if (params) {
      rb.query('cardId', params.cardId, {});
      rb.query('userId', params.userId, {});
      rb.query('completed', params.completed, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumCompleteTaskPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCompleteTaskPut$Plain(params?: {
    cardId?: number;
    userId?: number;
    completed?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumCompleteTaskPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumCompleteTaskPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCompleteTaskPut$Json$Response(params?: {
    cardId?: number;
    userId?: number;
    completed?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumCompleteTaskPutPath, 'put');
    if (params) {
      rb.query('cardId', params.cardId, {});
      rb.query('userId', params.userId, {});
      rb.query('completed', params.completed, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumCompleteTaskPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCompleteTaskPut$Json(params?: {
    cardId?: number;
    userId?: number;
    completed?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumCompleteTaskPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumAllLabelsGet
   */
  static readonly ApiScrumAllLabelsGetPath = '/api/Scrum/all-labels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumAllLabelsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumAllLabelsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<ScrumLabelDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumAllLabelsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumLabelDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumAllLabelsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumAllLabelsGet$Plain(params?: {
  }): Observable<ScrumLabelDtoListBaseResponse> {

    return this.apiScrumAllLabelsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumLabelDtoListBaseResponse>) => r.body as ScrumLabelDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumAllLabelsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumAllLabelsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<ScrumLabelDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumAllLabelsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumLabelDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumAllLabelsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumAllLabelsGet$Json(params?: {
  }): Observable<ScrumLabelDtoListBaseResponse> {

    return this.apiScrumAllLabelsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumLabelDtoListBaseResponse>) => r.body as ScrumLabelDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumLabelByIdGet
   */
  static readonly ApiScrumLabelByIdGetPath = '/api/Scrum/label-by-id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumLabelByIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumLabelByIdGet$Plain$Response(params?: {
    labelId?: number;
  }): Observable<StrictHttpResponse<ScrumLabelDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumLabelByIdGetPath, 'get');
    if (params) {
      rb.query('labelId', params.labelId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumLabelDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumLabelByIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumLabelByIdGet$Plain(params?: {
    labelId?: number;
  }): Observable<ScrumLabelDtoBaseResponse> {

    return this.apiScrumLabelByIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumLabelDtoBaseResponse>) => r.body as ScrumLabelDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumLabelByIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumLabelByIdGet$Json$Response(params?: {
    labelId?: number;
  }): Observable<StrictHttpResponse<ScrumLabelDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumLabelByIdGetPath, 'get');
    if (params) {
      rb.query('labelId', params.labelId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumLabelDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumLabelByIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumLabelByIdGet$Json(params?: {
    labelId?: number;
  }): Observable<ScrumLabelDtoBaseResponse> {

    return this.apiScrumLabelByIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumLabelDtoBaseResponse>) => r.body as ScrumLabelDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumAddSwimlanePost
   */
  static readonly ApiScrumAddSwimlanePostPath = '/api/Scrum/add-swimlane';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumAddSwimlanePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumAddSwimlanePost$Plain$Response(params?: {
    body?: SwimlaneListDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumAddSwimlanePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumAddSwimlanePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumAddSwimlanePost$Plain(params?: {
    body?: SwimlaneListDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumAddSwimlanePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumAddSwimlanePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumAddSwimlanePost$Json$Response(params?: {
    body?: SwimlaneListDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumAddSwimlanePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumAddSwimlanePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumAddSwimlanePost$Json(params?: {
    body?: SwimlaneListDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumAddSwimlanePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumUpdateSwimlanePut
   */
  static readonly ApiScrumUpdateSwimlanePutPath = '/api/Scrum/update-swimlane';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUpdateSwimlanePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateSwimlanePut$Plain$Response(params?: {
    body?: SwimlaneDetailDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUpdateSwimlanePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUpdateSwimlanePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateSwimlanePut$Plain(params?: {
    body?: SwimlaneDetailDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumUpdateSwimlanePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUpdateSwimlanePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateSwimlanePut$Json$Response(params?: {
    body?: SwimlaneDetailDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUpdateSwimlanePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUpdateSwimlanePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateSwimlanePut$Json(params?: {
    body?: SwimlaneDetailDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumUpdateSwimlanePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumDeleteSwimlaneDelete
   */
  static readonly ApiScrumDeleteSwimlaneDeletePath = '/api/Scrum/delete-swimlane';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumDeleteSwimlaneDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumDeleteSwimlaneDelete$Plain$Response(params?: {
    swimlaneId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumDeleteSwimlaneDeletePath, 'delete');
    if (params) {
      rb.query('swimlaneId', params.swimlaneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumDeleteSwimlaneDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumDeleteSwimlaneDelete$Plain(params?: {
    swimlaneId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumDeleteSwimlaneDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumDeleteSwimlaneDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumDeleteSwimlaneDelete$Json$Response(params?: {
    swimlaneId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumDeleteSwimlaneDeletePath, 'delete');
    if (params) {
      rb.query('swimlaneId', params.swimlaneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumDeleteSwimlaneDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumDeleteSwimlaneDelete$Json(params?: {
    swimlaneId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumDeleteSwimlaneDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumCardGet
   */
  static readonly ApiScrumCardGetPath = '/api/Scrum/card';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumCardGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardGet$Plain$Response(params?: {
    cardId?: number;
  }): Observable<StrictHttpResponse<ScrumCardDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumCardGetPath, 'get');
    if (params) {
      rb.query('cardId', params.cardId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumCardGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardGet$Plain(params?: {
    cardId?: number;
  }): Observable<ScrumCardDtoBaseResponse> {

    return this.apiScrumCardGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardDtoBaseResponse>) => r.body as ScrumCardDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumCardGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardGet$Json$Response(params?: {
    cardId?: number;
  }): Observable<StrictHttpResponse<ScrumCardDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumCardGetPath, 'get');
    if (params) {
      rb.query('cardId', params.cardId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumCardGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCardGet$Json(params?: {
    cardId?: number;
  }): Observable<ScrumCardDtoBaseResponse> {

    return this.apiScrumCardGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardDtoBaseResponse>) => r.body as ScrumCardDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumAddCardPost
   */
  static readonly ApiScrumAddCardPostPath = '/api/Scrum/add-card';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumAddCardPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumAddCardPost$Plain$Response(params?: {
    body?: ScrumCardDto
  }): Observable<StrictHttpResponse<ScrumCardDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumAddCardPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumAddCardPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumAddCardPost$Plain(params?: {
    body?: ScrumCardDto
  }): Observable<ScrumCardDtoBaseResponse> {

    return this.apiScrumAddCardPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardDtoBaseResponse>) => r.body as ScrumCardDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumAddCardPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumAddCardPost$Json$Response(params?: {
    body?: ScrumCardDto
  }): Observable<StrictHttpResponse<ScrumCardDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumAddCardPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumAddCardPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumAddCardPost$Json(params?: {
    body?: ScrumCardDto
  }): Observable<ScrumCardDtoBaseResponse> {

    return this.apiScrumAddCardPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardDtoBaseResponse>) => r.body as ScrumCardDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumToggleArchiveCardDelete
   */
  static readonly ApiScrumToggleArchiveCardDeletePath = '/api/Scrum/toggle-archive-card';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumToggleArchiveCardDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumToggleArchiveCardDelete$Plain$Response(params?: {
    cardId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumToggleArchiveCardDeletePath, 'delete');
    if (params) {
      rb.query('cardId', params.cardId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumToggleArchiveCardDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumToggleArchiveCardDelete$Plain(params?: {
    cardId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumToggleArchiveCardDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumToggleArchiveCardDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumToggleArchiveCardDelete$Json$Response(params?: {
    cardId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumToggleArchiveCardDeletePath, 'delete');
    if (params) {
      rb.query('cardId', params.cardId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumToggleArchiveCardDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumToggleArchiveCardDelete$Json(params?: {
    cardId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumToggleArchiveCardDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumCreateLabelPost
   */
  static readonly ApiScrumCreateLabelPostPath = '/api/Scrum/create-label';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumCreateLabelPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumCreateLabelPost$Plain$Response(params?: {
    body?: ScrumLabelDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumCreateLabelPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumCreateLabelPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumCreateLabelPost$Plain(params?: {
    body?: ScrumLabelDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumCreateLabelPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumCreateLabelPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumCreateLabelPost$Json$Response(params?: {
    body?: ScrumLabelDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumCreateLabelPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumCreateLabelPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumCreateLabelPost$Json(params?: {
    body?: ScrumLabelDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumCreateLabelPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumUpdateLabelPost
   */
  static readonly ApiScrumUpdateLabelPostPath = '/api/Scrum/update-label';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUpdateLabelPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateLabelPost$Plain$Response(params?: {
    body?: ScrumLabelDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUpdateLabelPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUpdateLabelPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateLabelPost$Plain(params?: {
    body?: ScrumLabelDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumUpdateLabelPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUpdateLabelPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateLabelPost$Json$Response(params?: {
    body?: ScrumLabelDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUpdateLabelPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUpdateLabelPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateLabelPost$Json(params?: {
    body?: ScrumLabelDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumUpdateLabelPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumAssignLabelPost
   */
  static readonly ApiScrumAssignLabelPostPath = '/api/Scrum/assign-label';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumAssignLabelPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumAssignLabelPost$Plain$Response(params?: {
    labelId?: number;
    cardId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumAssignLabelPostPath, 'post');
    if (params) {
      rb.query('labelId', params.labelId, {});
      rb.query('cardId', params.cardId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumAssignLabelPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumAssignLabelPost$Plain(params?: {
    labelId?: number;
    cardId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumAssignLabelPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumAssignLabelPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumAssignLabelPost$Json$Response(params?: {
    labelId?: number;
    cardId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumAssignLabelPostPath, 'post');
    if (params) {
      rb.query('labelId', params.labelId, {});
      rb.query('cardId', params.cardId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumAssignLabelPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumAssignLabelPost$Json(params?: {
    labelId?: number;
    cardId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumAssignLabelPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumMarkSurveyCompletePost
   */
  static readonly ApiScrumMarkSurveyCompletePostPath = '/api/Scrum/mark-survey-complete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumMarkSurveyCompletePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumMarkSurveyCompletePost$Plain$Response(params?: {
    cardId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumMarkSurveyCompletePostPath, 'post');
    if (params) {
      rb.query('cardId', params.cardId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumMarkSurveyCompletePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumMarkSurveyCompletePost$Plain(params?: {
    cardId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumMarkSurveyCompletePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumMarkSurveyCompletePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumMarkSurveyCompletePost$Json$Response(params?: {
    cardId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumMarkSurveyCompletePostPath, 'post');
    if (params) {
      rb.query('cardId', params.cardId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumMarkSurveyCompletePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumMarkSurveyCompletePost$Json(params?: {
    cardId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumMarkSurveyCompletePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumGetFilesGet
   */
  static readonly ApiScrumGetFilesGetPath = '/api/Scrum/get-files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumGetFilesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumGetFilesGet$Plain$Response(params?: {
    taskId?: number;
  }): Observable<StrictHttpResponse<TaskFileDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumGetFilesGetPath, 'get');
    if (params) {
      rb.query('taskId', params.taskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskFileDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumGetFilesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumGetFilesGet$Plain(params?: {
    taskId?: number;
  }): Observable<TaskFileDtoListBaseResponse> {

    return this.apiScrumGetFilesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TaskFileDtoListBaseResponse>) => r.body as TaskFileDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumGetFilesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumGetFilesGet$Json$Response(params?: {
    taskId?: number;
  }): Observable<StrictHttpResponse<TaskFileDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumGetFilesGetPath, 'get');
    if (params) {
      rb.query('taskId', params.taskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskFileDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumGetFilesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumGetFilesGet$Json(params?: {
    taskId?: number;
  }): Observable<TaskFileDtoListBaseResponse> {

    return this.apiScrumGetFilesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TaskFileDtoListBaseResponse>) => r.body as TaskFileDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumUploadFileTaskIdPost
   */
  static readonly ApiScrumUploadFileTaskIdPostPath = '/api/Scrum/upload-file/{taskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUploadFileTaskIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiScrumUploadFileTaskIdPost$Plain$Response(params: {
    taskId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<TaskFileDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUploadFileTaskIdPostPath, 'post');
    if (params) {
      rb.path('taskId', params.taskId, {});
      rb.query('filename', params.filename, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskFileDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUploadFileTaskIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiScrumUploadFileTaskIdPost$Plain(params: {
    taskId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<TaskFileDtoBaseResponse> {

    return this.apiScrumUploadFileTaskIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TaskFileDtoBaseResponse>) => r.body as TaskFileDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUploadFileTaskIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiScrumUploadFileTaskIdPost$Json$Response(params: {
    taskId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<TaskFileDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUploadFileTaskIdPostPath, 'post');
    if (params) {
      rb.path('taskId', params.taskId, {});
      rb.query('filename', params.filename, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskFileDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUploadFileTaskIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiScrumUploadFileTaskIdPost$Json(params: {
    taskId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<TaskFileDtoBaseResponse> {

    return this.apiScrumUploadFileTaskIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TaskFileDtoBaseResponse>) => r.body as TaskFileDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumUpdateFilePost
   */
  static readonly ApiScrumUpdateFilePostPath = '/api/Scrum/update-file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUpdateFilePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateFilePost$Plain$Response(params?: {
    body?: TaskFileDto
  }): Observable<StrictHttpResponse<TaskFileDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUpdateFilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskFileDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUpdateFilePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateFilePost$Plain(params?: {
    body?: TaskFileDto
  }): Observable<TaskFileDtoBaseResponse> {

    return this.apiScrumUpdateFilePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TaskFileDtoBaseResponse>) => r.body as TaskFileDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUpdateFilePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateFilePost$Json$Response(params?: {
    body?: TaskFileDto
  }): Observable<StrictHttpResponse<TaskFileDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUpdateFilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskFileDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUpdateFilePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateFilePost$Json(params?: {
    body?: TaskFileDto
  }): Observable<TaskFileDtoBaseResponse> {

    return this.apiScrumUpdateFilePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TaskFileDtoBaseResponse>) => r.body as TaskFileDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumDownloadFileFileIdGet
   */
  static readonly ApiScrumDownloadFileFileIdGetPath = '/api/Scrum/download-file/{fileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumDownloadFileFileIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumDownloadFileFileIdGet$Response(params: {
    fileId: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumDownloadFileFileIdGetPath, 'get');
    if (params) {
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumDownloadFileFileIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumDownloadFileFileIdGet(params: {
    fileId: number;
  }): Observable<string> {

    return this.apiScrumDownloadFileFileIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiScrumDeleteFileFileIdDelete
   */
  static readonly ApiScrumDeleteFileFileIdDeletePath = '/api/Scrum/delete-file/{fileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumDeleteFileFileIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumDeleteFileFileIdDelete$Plain$Response(params: {
    fileId: number;
  }): Observable<StrictHttpResponse<TaskFileDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumDeleteFileFileIdDeletePath, 'delete');
    if (params) {
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskFileDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumDeleteFileFileIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumDeleteFileFileIdDelete$Plain(params: {
    fileId: number;
  }): Observable<TaskFileDtoBaseResponse> {

    return this.apiScrumDeleteFileFileIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TaskFileDtoBaseResponse>) => r.body as TaskFileDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumDeleteFileFileIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumDeleteFileFileIdDelete$Json$Response(params: {
    fileId: number;
  }): Observable<StrictHttpResponse<TaskFileDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumDeleteFileFileIdDeletePath, 'delete');
    if (params) {
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaskFileDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumDeleteFileFileIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumDeleteFileFileIdDelete$Json(params: {
    fileId: number;
  }): Observable<TaskFileDtoBaseResponse> {

    return this.apiScrumDeleteFileFileIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TaskFileDtoBaseResponse>) => r.body as TaskFileDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumCommentsGet
   */
  static readonly ApiScrumCommentsGetPath = '/api/Scrum/comments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumCommentsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCommentsGet$Plain$Response(params?: {
    scrumCardId?: number;
  }): Observable<StrictHttpResponse<ScrumCardCommentDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumCommentsGetPath, 'get');
    if (params) {
      rb.query('scrumCardId', params.scrumCardId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardCommentDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumCommentsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCommentsGet$Plain(params?: {
    scrumCardId?: number;
  }): Observable<ScrumCardCommentDtoListBaseResponse> {

    return this.apiScrumCommentsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardCommentDtoListBaseResponse>) => r.body as ScrumCardCommentDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumCommentsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCommentsGet$Json$Response(params?: {
    scrumCardId?: number;
  }): Observable<StrictHttpResponse<ScrumCardCommentDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumCommentsGetPath, 'get');
    if (params) {
      rb.query('scrumCardId', params.scrumCardId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardCommentDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumCommentsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumCommentsGet$Json(params?: {
    scrumCardId?: number;
  }): Observable<ScrumCardCommentDtoListBaseResponse> {

    return this.apiScrumCommentsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardCommentDtoListBaseResponse>) => r.body as ScrumCardCommentDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumAddCommentPost
   */
  static readonly ApiScrumAddCommentPostPath = '/api/Scrum/add-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumAddCommentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumAddCommentPost$Plain$Response(params?: {
    body?: ScrumCardCommentDto
  }): Observable<StrictHttpResponse<ScrumCardCommentDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumAddCommentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardCommentDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumAddCommentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumAddCommentPost$Plain(params?: {
    body?: ScrumCardCommentDto
  }): Observable<ScrumCardCommentDtoBaseResponse> {

    return this.apiScrumAddCommentPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardCommentDtoBaseResponse>) => r.body as ScrumCardCommentDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumAddCommentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumAddCommentPost$Json$Response(params?: {
    body?: ScrumCardCommentDto
  }): Observable<StrictHttpResponse<ScrumCardCommentDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumAddCommentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ScrumCardCommentDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumAddCommentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumAddCommentPost$Json(params?: {
    body?: ScrumCardCommentDto
  }): Observable<ScrumCardCommentDtoBaseResponse> {

    return this.apiScrumAddCommentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ScrumCardCommentDtoBaseResponse>) => r.body as ScrumCardCommentDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumUpdateCommentPut
   */
  static readonly ApiScrumUpdateCommentPutPath = '/api/Scrum/update-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUpdateCommentPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateCommentPut$Plain$Response(params?: {
    body?: ScrumCardCommentDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUpdateCommentPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUpdateCommentPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateCommentPut$Plain(params?: {
    body?: ScrumCardCommentDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumUpdateCommentPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUpdateCommentPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateCommentPut$Json$Response(params?: {
    body?: ScrumCardCommentDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUpdateCommentPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUpdateCommentPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateCommentPut$Json(params?: {
    body?: ScrumCardCommentDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumUpdateCommentPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumDeleteCommentDelete
   */
  static readonly ApiScrumDeleteCommentDeletePath = '/api/Scrum/delete-comment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumDeleteCommentDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumDeleteCommentDelete$Plain$Response(params?: {
    commentId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumDeleteCommentDeletePath, 'delete');
    if (params) {
      rb.query('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumDeleteCommentDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumDeleteCommentDelete$Plain(params?: {
    commentId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumDeleteCommentDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumDeleteCommentDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumDeleteCommentDelete$Json$Response(params?: {
    commentId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumDeleteCommentDeletePath, 'delete');
    if (params) {
      rb.query('commentId', params.commentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumDeleteCommentDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumDeleteCommentDelete$Json(params?: {
    commentId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumDeleteCommentDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumSwimlaneNotificationsGet
   */
  static readonly ApiScrumSwimlaneNotificationsGetPath = '/api/Scrum/swimlane-notifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumSwimlaneNotificationsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumSwimlaneNotificationsGet$Plain$Response(params?: {
    swimlaneId?: number;
  }): Observable<StrictHttpResponse<SwimlaneNotificationDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumSwimlaneNotificationsGetPath, 'get');
    if (params) {
      rb.query('swimlaneId', params.swimlaneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SwimlaneNotificationDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumSwimlaneNotificationsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumSwimlaneNotificationsGet$Plain(params?: {
    swimlaneId?: number;
  }): Observable<SwimlaneNotificationDtoListBaseResponse> {

    return this.apiScrumSwimlaneNotificationsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SwimlaneNotificationDtoListBaseResponse>) => r.body as SwimlaneNotificationDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumSwimlaneNotificationsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumSwimlaneNotificationsGet$Json$Response(params?: {
    swimlaneId?: number;
  }): Observable<StrictHttpResponse<SwimlaneNotificationDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumSwimlaneNotificationsGetPath, 'get');
    if (params) {
      rb.query('swimlaneId', params.swimlaneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SwimlaneNotificationDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumSwimlaneNotificationsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScrumSwimlaneNotificationsGet$Json(params?: {
    swimlaneId?: number;
  }): Observable<SwimlaneNotificationDtoListBaseResponse> {

    return this.apiScrumSwimlaneNotificationsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SwimlaneNotificationDtoListBaseResponse>) => r.body as SwimlaneNotificationDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiScrumUpdateSwimlaneNotificationsPost
   */
  static readonly ApiScrumUpdateSwimlaneNotificationsPostPath = '/api/Scrum/update-swimlane-notifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUpdateSwimlaneNotificationsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateSwimlaneNotificationsPost$Plain$Response(params?: {
    body?: SwimlaneNotificationsDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUpdateSwimlaneNotificationsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUpdateSwimlaneNotificationsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateSwimlaneNotificationsPost$Plain(params?: {
    body?: SwimlaneNotificationsDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumUpdateSwimlaneNotificationsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScrumUpdateSwimlaneNotificationsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateSwimlaneNotificationsPost$Json$Response(params?: {
    body?: SwimlaneNotificationsDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScrumService.ApiScrumUpdateSwimlaneNotificationsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScrumUpdateSwimlaneNotificationsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiScrumUpdateSwimlaneNotificationsPost$Json(params?: {
    body?: SwimlaneNotificationsDto
  }): Observable<BooleanBaseResponse> {

    return this.apiScrumUpdateSwimlaneNotificationsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
